<script lang="ts">
    import { onMount } from 'svelte';

    export let element: HTMLElement;
    export let color: string;
    export let label: string = '';

    let overlayDiv: HTMLDivElement;

    const rect = element.getBoundingClientRect();
    let x = rect.x;
    let y = rect.y;
    let width = rect.width;
    let height = rect.height;

</script>

{#key element}
<div 
    class="fixed border-2 z-[2000]" 
    style="top: {y}px; left: {x}px; width: {width}px; height: {height}px; border-color: {color}; color: {color};"
>
    {#if label}
        <span class="absolute top-[-22px] right-[-2px] py-1 text-xs font-mono whitespace-nowrap pointer-events-none">{label}</span>
    {/if}
</div>
{/key}