import { LPOMonitor, LightpostUI, setConfig } from '../../components';

import { EmbeddedConfig } from './embed_config';

// Get the script's origin as server URL
const scriptElement = document.currentScript as HTMLScriptElement;
const server = scriptElement?.src ? new URL(scriptElement.src).origin : window.location.origin;

// Get the LPO ID from window config
const config = (window as any).LightpostConfig;
if (!config?.apiKey) {
    console.error('Lightpost: Missing required API key in configuration');
    throw new Error('Lightpost: Configuration error');
}

console.log("Lightpost: Embed mode. Server: " + server + " API key: " + config.apiKey);

// Initialize with embed config. No accelerator for now.
setConfig(new EmbeddedConfig(server, config.apiKey));

// Initial call to start the loop
LPOMonitor.init();
LightpostUI.init();




