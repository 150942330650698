import { gql } from 'graphql-request';
import { makeGraphQLRequest, UIControl, UserInteraction } from './graphql-client';
import { LightpostUI } from './ui';
import { resolveElement } from './elements';

export async function getControls(url: string): Promise<UIControl[]> {
  try {
    const query = gql`
      query GetControls($url: String!) {
        controls(url: $url) {
          url
          path
          elementLabel
          description
        }
      }
    `;

    const response = await makeGraphQLRequest<{
      controls: UIControl[];
    }>(query, { url });

    return response.controls;
  } catch (error) {
    console.error('Error fetching controls:', error);
    return [];
  }
}

export async function getControlFromInteraction(ui: UserInteraction) {
  const controls = await getControls(window.location.href);
  for (const control of controls) {
      if (control.path == ui.path) {
          return control;
      }
  }
  return null;
}


export async function highlightControls() {
  const controls = await getControls(window.location.href);
  for (const control of controls) {
    const element = resolveElement(control.path, control.elementLabel, document);
    if (element.length > 0) {
      LightpostUI.showTooltip(3000, element[0], control.description);
    }
  }
}