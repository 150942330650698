import { eventList, createEventFromDOM, createNavigationEvent, createTextEvent, createTitleEvent } from './eventlist';
import { resolveElement, whyInteractive } from './elements';
import { LightpostUI } from './ui';
import { highlightControls } from './controls';


export class LPOMonitor {

  static lastLocation: string = "";
  static lastInnerText: string = "";
  static lastTitle: string = "";
  static nextTextCheck: number = 0;

  static handleEvent(event: Event) {

    let elem = event.target as HTMLElement;
    if (!elem) {
      return;
    }

    let reasons: string[] = whyInteractive(elem, event.type);
    for (let i = 0; i < 6; i++) {
      const parent = elem.parentElement;
      if (!parent) {
        break;
      }
      const r_parent = whyInteractive(parent, event.type);

      if (reasons.length && r_parent.length == 0) {
        break;
      }
      reasons = r_parent;
      elem = parent;

    }

    if (!reasons.length) {
      return;
    }

    const url = window.location.href;

    console.log("LPO: Element is interactive because:", reasons.join(', '));

    // Schedule a text check 1 sec after every interactive event
    LPOMonitor.nextTextCheck = Date.now() + 1000;

    createEventFromDOM(url, event, elem).then(lpoevent => {
      eventList.push(lpoevent);
      LightpostUI.temporaryHighlight(500, elem, 'red', lpoevent.action);
    });
  }


  static async predictNextEvent() {
    console.log("LPO: Predicting next event");
    const prediction = await eventList.predict();
    console.log("LPO: Predicted next events:", prediction);
    
    // Highlight most recent event in red
    if (prediction.past.length > 0) {
      const lastEvent = prediction.past[prediction.past.length - 1];
      const lastElems = resolveElement(lastEvent.path, lastEvent.elementLabel, document);
      if (lastElems.length > 0) {
        LightpostUI.temporaryHighlight(1000, lastElems[0], 'red', 'Last Event');
      }
    }

    // Highlight predicted next event in green
    if (prediction.future.length > 0) {
      const nextEvent = prediction.future[0];
      const nextElems = resolveElement(nextEvent.path, nextEvent.elementLabel, document);
      if (nextElems.length > 0) {
        LightpostUI.temporaryHighlight(1000, nextElems[0], 'green', 
          (nextEvent.probability * 100).toFixed(1) + '%');
      }
    }
  }

  static async checkLocationAndText() {
    const url = window.location.href;
    if (url != LPOMonitor.lastLocation) {
      LPOMonitor.lastLocation = url;
      LPOMonitor.lastInnerText = "";
      LPOMonitor.lastTitle = "";

      const ievent = await createNavigationEvent(url);
      eventList.push(ievent);
      LPOMonitor.nextTextCheck = Date.now() + 1000;
    }

    if (Date.now() > LPOMonitor.nextTextCheck && document.readyState === 'complete') {
      if (document.title !== LPOMonitor.lastTitle) {
        LPOMonitor.lastTitle = document.title;
        const ievent = await createTitleEvent(document.title);
        eventList.push(ievent);
      }

      const text = document.body.innerText;
      if (text !== LPOMonitor.lastInnerText) {
        LPOMonitor.lastInnerText = text;
        const ievent = await createTextEvent(text);
        eventList.push(ievent);
      }

      LPOMonitor.nextTextCheck = Date.now() + 10000;
    }

  }

  
  public static init() {
    document.addEventListener('keydown', async (event) => {
      // Ctrl+Alt+P to predict the next event
      if (event.ctrlKey && event.altKey) {
        if (event.key.toLowerCase() === 'p') {
          LPOMonitor.predictNextEvent();
        }
        if (event.key.toLowerCase() === 'c') {
          highlightControls();
        }      }
    });

    for (let eventType of ['click', 'keydown', 'keyup', 'mousedown', 'mouseup', 'change', 'dblclick', 'contextmenu']) {
      document.addEventListener(eventType, LPOMonitor.handleEvent, { capture: true });
    }
    
    setInterval(LPOMonitor.checkLocationAndText, 500);
  }

}
