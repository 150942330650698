/**
 * Generates a UUID v4 string, attempting to use crypto.randomUUID() first,
 * with a fallback implementation if crypto.randomUUID is not available
 * @returns A UUID v4 string (e.g., "a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11")
 */
export function randomID(): string {
    try {
        // First try to use crypto.randomUUID
        if (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function') {
            return crypto.randomUUID();
        }
    } catch (e) {
        // If any errors occur, fall through to the fallback
    }

    // Fallback implementation
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}