import { Config, randomID } from '../../components';

export class EmbeddedConfig implements Config {
  private server: string;
  private apiKey: string;
  constructor(server: string, apiKey: string) {
    this.server = server;
    this.apiKey = apiKey;
  }

  
  public async getServer(): Promise<string> {
    return this.server;

  }

  public async setServer(value: string): Promise<void> {
    alert("Configuration editing is not supported in embedded mode.");
  }
  
  public async getApiKey(): Promise<string> {
    return this.apiKey;
  }

  public async setApiKey(_value: string): Promise<void> {
    alert("Configuration editing is not supported in embedded mode.");
  }

  public async getUserId(): Promise<string> {
    // Try to get existing userId from localStorage
    const userId = localStorage.getItem('lightpost_userid');
    if (userId) {
      return userId;
    }

    // Generate a new random ID
    const newUserId = randomID();

    // Store it in localStorage
    localStorage.setItem('lightpost_userid', newUserId);
    return newUserId;
  }
}
