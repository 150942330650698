<script lang="ts">
    import Icon from '@iconify/svelte';
    import MarkdownIt from 'markdown-it';
    // @ts-ignore: Suppress missing type declaration warning
    import markdownItKatexGpt from 'markdown-it-katex-gpt';
    import { getChatClient } from './shared/chat-client';
    import { getConfig } from './shared/config';

    import { onMount } from 'svelte';

    const md = new MarkdownIt({
        linkify: false,
        breaks: true
    }).use(markdownItKatexGpt).disable(["link"]);

    function renderMarkdown(content: string) {
        // Replace [path|label] with a special marker that won't be processed by markdown
        const processedContent = content.replace(
            /\[([^|]+)\|([^\]]+)\]/g,
            (_, path, label) => `%%%INTERACTIVE:${path}:${label}%%%`
        );
        
        // Render markdown
        let htmlContent = md.render(processedContent);
        
        // Replace our markers with clickable spans that include the onclick handler
        return htmlContent.replace(
            /%%%INTERACTIVE:([^:]+):([^%]+)%%%/g,
            (_, path, label) => `<a
                class="interactive-element cursor-pointer text-theme-dark underline" 
                data-path="${path}" 
                data-label="${label}"
            >${label}</a>`
        );
    }


    export let initialMessage = "";

    let chatEndRef: HTMLElement;

    let openaiClient: any;
    let userId: string;
    onMount(async () => {
        openaiClient = await getChatClient();
        userId = await getConfig().getUserId();
    });

    let messages: { role: string, content: string }[] = [];
    
    // Load messages from sessionStorage on initialization
    const storedMessages = sessionStorage.getItem(`chat_${userId}`);
    if (storedMessages) {
        messages = JSON.parse(storedMessages);
    } else if (initialMessage) {
        messages = [
            { role: 'assistant', content: initialMessage, },
        ];
    }

    // Save messages to sessionStorage whenever they change
    $: {
        sessionStorage.setItem(`chat_${userId}`, JSON.stringify(messages));
    }

    let newMessage = '';
    let isLoading = false;

    // Update clearChat to also clear sessionStorage
    export function clearChat() {
        messages = [];
        newMessage = '';
        sessionStorage.removeItem(`chat_${userId}`);
    }

    async function handleSubmit(e: Event) {
        if (newMessage.trim()) {
            const userMessage = { role: 'user', content: newMessage.trim() };
            messages = [...messages, userMessage];
            newMessage = '';
            const textarea = e.target as HTMLTextAreaElement;
            textarea.style.height = '48px';
            
            isLoading = true;
            try {
                const assistantMessage = { role: 'assistant', content: '' };
                messages = [...messages, assistantMessage];

                // @ts-ignore: context is not non-standard Lightpost One AI parameter
                const stream = await openaiClient.chat.completions.create({
                    model: "lightpost-chat",
                    stream: true,
                    messages: messages.map(m => ({
                        role: m.role as 'user' | 'assistant',
                        content: m.content,
                    })),

                    context: {
                        userId: userId,
                        pageUrl: window.location.href,
                    }
                });

                for await (const chunk of stream) {
                    const content = chunk.choices[0]?.delta?.content || '';
                    assistantMessage.content += content;
                    messages = [...messages];
                    chatEndRef?.scrollIntoView({ behavior: 'smooth' });

                }
            } catch (error) {
                console.error('Error:', error);
                messages = [...messages, { role: 'assistant', content: 'Sorry, there was an error processing your request.' }];
                chatEndRef?.scrollIntoView({ behavior: 'smooth' });
            } finally {
                isLoading = false;
            }
        }
    }

    function handleKeydown(e: KeyboardEvent) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    }

    function autoGrow(e: Event) {
        const textarea = e.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        const newHeight = Math.min(textarea.scrollHeight, 192);
        textarea.style.height = `${newHeight}px`;
        textarea.style.overflowY = newHeight === 192 ? 'auto' : 'hidden';
    }

    setTimeout(() => {
        if (chatEndRef) {
            chatEndRef.scrollIntoView({ behavior: 'smooth' });
        }
    }, 0);

</script>
<div>
    <div class="space-y-4">
        {#each messages as message}
        <div class={`prose max-w-none p-3 rounded-lg shadow-lg ${message.role === 'assistant' ? 'mr-6 bg-surface' : 'ml-6 bg-surface-dark'}`}>
            {@html renderMarkdown(message.content)}
        </div>
        {/each}
    </div>

    <form on:submit|preventDefault={(e) => handleSubmit(e)} class="my-2 ml-6">
        <div class="relative flex">
            <textarea
                bind:value={newMessage}
                on:input={autoGrow}
                on:keydown={handleKeydown}
                aria-label="Chat input"
                placeholder="Ask Lightpost One..."
                class="w-full h-[48px] overflow-y-hidden p-3 pr-12 rounded-lg focus:outline-none focus:border-theme resize-none"
                rows="1"
            ></textarea>
            <button 
                type="submit"
                aria-label="Send message"
                class="absolute right-2 top-[8px] p-1.5 hover:bg-surface-dark rounded-lg focus:outline-none"
            >
                <Icon icon="material-symbols:send" class="w-5 h-5 text-theme" />
            </button>
        </div>
    </form>
    <div id="chat-end" bind:this={chatEndRef}></div>
</div> 