export interface Config {
  getServer(): Promise<string>;
  setServer(value: string): Promise<void>;

  getApiKey(): Promise<string>;
  setApiKey(value: string): Promise<void>;

  getUserId(): Promise<string>;
}

let currentConfig: Config;

export function setConfig(config: Config): void {
  currentConfig = config;
}

export function getConfig(): Config {
  return currentConfig;
}