<script lang="ts">
    import Icon from "@iconify/svelte";
    import { addIcon } from "@iconify/svelte";
    import { fade } from "svelte/transition";
    import ChangeLog from "./ChangeLog.svelte";
    import Chat from "./Chat.svelte";

    let isPanelOpen = false;

    if (typeof window !== "undefined") {
        const savedState = sessionStorage.getItem("panelOpen");
        isPanelOpen = savedState === "true";
    }

    function togglePanel() {
        isPanelOpen = !isPanelOpen;
        sessionStorage.setItem("panelOpen", isPanelOpen.toString());
    }

    // Register the LPO logo icon
    addIcon("custom:lpo-logo", {
        body: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.848 115.006">
                 <g id="lpo-logo" data-name="Livello 1">
                   <g>
                     <path d="M42.765,68.523L0,93.053v9.874l55.944-31.855v43.934h9.198v-33.52c0-11.484-12.416-18.676-22.377-12.962Z" fill="currentColor"/>
                     <path d="M84.904,43.934V0h-9.198v33.52c0,11.484,12.416,18.676,22.377,12.962l42.765-24.529v-9.874l-55.944,31.855Z" fill="currentColor"/>
                     <path d="M75.706,69.762v45.243h9.198v-55.32l55.944,31.855v-9.874l-43.057-24.697c-9.832-5.639-22.085,1.458-22.085,12.793Z" fill="currentColor"/>
                     <path d="M55.944,55.32L0,23.465v9.874l43.057,24.697c9.832,5.639,22.085-1.458,22.085-12.793V0h-9.198v55.32Z" fill="currentColor"/>
                   </g>
                 </g>
               </svg>`,
        width: 140.848,
        height: 115.006,
    });
</script>

<div class="font-sans text-theme">
    {#if isPanelOpen}
        <div
            class="fixed z-[2000] bottom-0 right-0 w-full h-[300px] bg-theme flex flex-row"
            transition:fade={{ duration: 100 }}
        >
            <div class="w-[300px] border-r border-surface/20">
                <ChangeLog />
            </div>
            <div class="w-full p-2 overflow-y-auto">
                <Chat />
            </div>
            <button
                on:click={togglePanel}
                title="Close Lightpost One chat"
                aria-label="Close Lightpost One chat"
                class="self-start p-2"
            >
                <Icon
                    icon="mdi:close"
                    class="w-8 h-8 bg-theme rounded-lg p-1 text-surface"
                />
            </button>
        </div>
    {:else}
        <button
            class="fixed bottom-0 right-0 pb-4 pr-2 z-[2000]"
            on:click={togglePanel}
            title="Open Lightpost One chat"
            aria-label="Open Lightpost One chat"
        >
            <Icon
                icon="custom:lpo-logo"
                class="w-8 h-8 bg-theme rounded-lg p-1 text-surface"
            />
        </button>
    {/if}
</div>
