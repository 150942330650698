
interface CacheItem<T> {
  data: T;
  timestamp: number;
  dirty: boolean;
}

const STORAGE_PREFIX = 'lightpostone_';

export class LightpostCache<T> {
  constructor(
    private storageKey: string,
    private maxItems: number = 500,
    private getItemId: (item: T) => string | number
  ) {}

  getItems(): CacheItem<T>[] {
    const stored = sessionStorage.getItem(STORAGE_PREFIX + this.storageKey);
    const items: CacheItem<T>[] = stored ? JSON.parse(stored) : [];
    if (!Array.isArray(items)) {
      // Clear invalid format and return empty array
      sessionStorage.removeItem(STORAGE_PREFIX + this.storageKey);
      console.warn('LPO: Invalid cache format, clearing cache', items);
      return [];
    }
    return items;
  }

  get(): T[] {
    return this.getItems().map(item => item.data);
  }

  add(item: T) {
    const stored = sessionStorage.getItem(STORAGE_PREFIX + this.storageKey);
    const items: CacheItem<T>[] = stored ? JSON.parse(stored) : [];
    
    items.push({
      data: item,
      timestamp: Date.now(),
      dirty: true
    });

    // Keep only the most recent N items
    const limitedItems = items.slice(-this.maxItems);
    sessionStorage.setItem(STORAGE_PREFIX + this.storageKey, JSON.stringify(limitedItems));
  }

  getDirtyItems(): T[] {
    return this.getItems()
      .filter(item => item.dirty)
      .map(item => item.data);
  }

  markItemClean(id: string | number): void {
    const items = this.getItems();
    const found = items.find(item => this.getItemId(item.data) === id);
    if (!found) {
      console.warn('LPO: Marking item clean failed, item not found', id);
      return;
    }

    found.dirty = false;
    sessionStorage.setItem(STORAGE_PREFIX + this.storageKey, JSON.stringify(items));
  }
} 
