import { getConfig } from './config';
import { gql } from 'graphql-request';
import { getElementPath, getElementText } from './elements';
import { makeGraphQLRequest, LPOEvent, WorkflowPrediction } from './graphql-client';
import { randomID } from './util';
import { LightpostCache } from './cache';


export class EventList {
  private cache: LightpostCache<LPOEvent>;
  private syncInterval: number;
  public lastEvent: LPOEvent | null = null;

  constructor() {
    this.cache = new LightpostCache<LPOEvent>('event_log', 32, ev => ev.id);
    
    // Start the periodic sync
    this.syncInterval = window.setInterval(() => this.periodicSync(), 1000);
  }

  private periodicSync() {
    try {
      const dirtyEvents = this.cache.getDirtyItems();
      dirtyEvents.forEach(event => this.syncEvent(event));
    } catch (error) {
      console.warn('LPO: Error during periodic sync:', error);
    }
  }

  public getIsRecording(): boolean {
    return sessionStorage.getItem('isRecording') === 'true';
  }

  public setIsRecording(value: boolean): void {
    sessionStorage.setItem('isRecording', value.toString());
  }

  private async syncEvent(event: LPOEvent) {
    try {
      const mutation = gql`
        mutation RecordEvent($event: EventInput!) {
          recordEvent(event: $event) {
            id
          }
        }
      `;
      
      await makeGraphQLRequest(mutation, { event });
      this.cache.markItemClean(event.id);
    } catch (error) {
      console.warn('LPO: Error pushing event to server. Never mind.', error);
    }
  }

  push(event: LPOEvent) {
    this.cache.add(event);
    this.lastEvent = event;
    // Fire and forget
    this.syncEvent(event);
  }

  async getItems(): Promise<LPOEvent[]> {
    return this.cache.getItems().map(item => item.data);
  }

  async predict(): Promise<WorkflowPrediction> {
    const userId = await getConfig().getUserId();
    const query = gql`
      query GetWorkflowPrediction($userId: String!) {
        getWorkflowPrediction(userId: $userId) {
          description
          past {
            action
            url
            path
            elementLabel
            probability
          }
          future {
            action
            url
            path
            elementLabel
            probability
          }
        }
      }
    `;
    
    try {
      const { getWorkflowPrediction } = await makeGraphQLRequest<{ 
        getWorkflowPrediction: WorkflowPrediction;
      }>(query, { userId: userId });

      return getWorkflowPrediction;
    } catch (error) {
      console.error('LPO: Error predicting event:', error);
      return {
        description: "Error fetching prediction",
        past: [],
        future: []
      };
    }
  }
}

export const eventList = new EventList();

export const createEventFromDOM = async (
  url: string,
  event: Event,
  element: HTMLElement
): Promise<LPOEvent> => {
  let x: number, y: number;
  if (event instanceof MouseEvent) {
    x = Math.round(event.clientX + window.scrollX);
    y = Math.round(event.clientY + window.scrollY);
  } else {
    const rect = element.getBoundingClientRect();
    x = Math.round(rect.left + window.scrollX);
    y = Math.round(rect.top + window.scrollY);
  }
  
  let value = '';
  if (element instanceof HTMLInputElement || 
      element instanceof HTMLTextAreaElement || 
      element instanceof HTMLSelectElement) {
    value = element.value;
  }

  const userId = await getConfig().getUserId();
  const isRecording = eventList.getIsRecording();
  if (isRecording) {
    console.log("LPO: Recording active");
  }
  
  return {
    id: randomID(),
    userId,
    url,
    timestamp: Date.now(),
    action: event.type.toUpperCase(),
    path: getElementPath(element),
    elementLabel: getElementText(element),
    outerHTML: element.outerHTML,
    x,
    y,
    weight: isRecording ? 100.0 : 1.0,
    value
  };
};

export async function createNavigationEvent(url: string): Promise<LPOEvent> {
  const userId = await getConfig().getUserId();

  const ievent: LPOEvent = {
    id: randomID(),
    userId: userId,
    url: url, // For navigate events, the url is the new page
    timestamp: Date.now(),
    action: "NAVIGATE",
    path: "",
    elementLabel: "",
    outerHTML: "",
    x: 0,
    y: 0,
    value: "",
    weight: 1.0
  };
  return ievent;
}


export async function createTextEvent(text: string): Promise<LPOEvent> {
  const userId = await getConfig().getUserId();
  if (!text.endsWith('\n')) {
    text = text + '\n';
  }

  const ievent: LPOEvent = {
    id: randomID(),
    userId: userId,
    url: window.location.href,
    timestamp: Date.now(),
    action: "TEXTCHANGE",
    path: "",
    elementLabel: "",
    outerHTML: "",
    x: 0,
    y: 0,
    value: text,
    weight: 1.0
  };
  return ievent;
}

export async function createTitleEvent(title: string): Promise<LPOEvent> {
  const userId = await getConfig().getUserId();
  const ievent: LPOEvent = {
    id: randomID(),
    userId: userId,
    url: window.location.href,
    timestamp: Date.now(),
    action: "TITLECHANGE",
    path: "",
    elementLabel: "",
    outerHTML: "",
    x: 0,
    y: 0,
    value: title,
    weight: 1.0
  };
  return ievent;
}
