<script lang="ts">
    import { onMount } from 'svelte';

    export let element: HTMLElement;
    export let text: string;

    let tooltipDiv: HTMLDivElement;
    let arrowDiv: HTMLDivElement;
    let visible = false;

    function updatePosition() {
        const rect = element.getBoundingClientRect();
        const tooltip = tooltipDiv.getBoundingClientRect();
        
        // Position tooltip above the element
        let x = rect.left + (rect.width / 2) - (tooltip.width / 2);
        let y = rect.top - tooltip.height - 8;

        // Ensure tooltip stays within viewport
        x = Math.max(8, Math.min(x, window.innerWidth - tooltip.width - 8));
        y = Math.max(8, y);

        tooltipDiv.style.left = `${x}px`;
        tooltipDiv.style.top = `${y}px`;

        // Position arrow directly above the element, regardless of tooltip position
        const arrowX = rect.left + (rect.width / 2) - 8; // 8px is half the arrow width
        arrowDiv.style.left = `${arrowX}px`;
        arrowDiv.style.top = `${y + tooltip.height}px`;
    }

    onMount(() => {
        updatePosition();
    });
</script>

<div
    bind:this={tooltipDiv}
    class="fixed z-[2000] px-2 py-1 text-sm text-white bg-[#1c3c3c] rounded shadow-lg pointer-events-none"
>
    {text}
</div>

<div 
    bind:this={arrowDiv}
    class="fixed z-[2000] w-0 h-0 pointer-events-none
           border-l-[8px] border-l-transparent 
           border-r-[8px] border-r-transparent 
           border-t-[8px] border-t-[#1c3c3c]"
>
</div>