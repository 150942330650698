import OpenAI from "openai";
import { getConfig } from "./config";

export async function getChatClient() {
    let server = await getConfig().getServer();

    let baseURL: string;
    if (server == "http://localhost:1357") {
    // For local development, use the local lpm server
    baseURL = "http://localhost:1357/v1";
    } else {
    baseURL = server + "/lpm/v1";
    }

    return new OpenAI({
        apiKey: await getConfig().getApiKey(),
        baseURL: baseURL,
        dangerouslyAllowBrowser: true,
        maxRetries: 0
    });
}
