<script lang="ts">
    import Icon from "@iconify/svelte";
    
    // Define the type for our changelog items
    interface ChangeLogItem {
        id: string;
        description: string;
        timestamp: Date;
    }

    // Mock data
    let changeLogItems: ChangeLogItem[] = [
        {
            id: "12",
            description: "Updated user interface components",
            timestamp: new Date("2024-03-15T10:30:00")
        },
        {
            id: "11",
            description: "Fixed authentication bug in login flow",
            timestamp: new Date("2024-03-14T15:45:00")
        },
        {
            id: "10",
            description: "Added new dashboard",
            timestamp: new Date("2024-03-13T09:20:00")
        },
        {
            id: "9",
            description: "Performance optimization for data loading at runtime",
            timestamp: new Date("2024-03-12T16:15:00")
        },
        {
            id: "8",
            description: "Implemented user feedback system",
            timestamp: new Date("2024-03-11T14:20:00")
        },
        {
            id: "7",    
            description: "Updated API documentation",
            timestamp: new Date("2024-03-10T11:30:00")
        },
        {
            id: "6",
            description: "Added dark mode support",
            timestamp: new Date("2024-03-09T16:45:00")
        },
        {
            id: "5",
            description: "Fixed responsive layout issues",
            timestamp: new Date("2024-03-08T09:15:00")
        },
        {
            id: "4",
            description: "Implemented search functionality",
            timestamp: new Date("2024-03-07T13:20:00")
        },
        {
            id: "3",
            description: "Added export to PDF feature",
            timestamp: new Date("2024-03-06T15:10:00")
        },
        {
            id: "2",
            description: "Security patches applied",
            timestamp: new Date("2024-03-05T10:25:00")
        },
        {
            id: "1",
            description: "Initial release",
            timestamp: new Date("2024-03-04T08:00:00")
        }
    ];

    // Function to delete an item
    function deleteItem(id: string) {
        changeLogItems = changeLogItems.filter(item => item.id !== id);
    }

    // Function to handle item click
    function handleItemClick(id: string) {
        // TODO: Implement click handling logic
        console.log(`Clicked item with id: ${id}`);
    }
</script>

<div class="h-full p-2 bg-theme-dark overflow-y-auto">
    <div class="space-y-0 relative pl-3">
        <!-- Add vertical line -->
        <div class="absolute left-[6px] top-5 bottom-0 w-[1px] bg-surface/30"></div>
        
        {#each changeLogItems as item, index (item.id)}
            <div class="flex items-center group px-2 py-1 rounded hover:bg-surface/20 relative">
                <Icon 
                    icon={index === 0 ? "mdi:circle-outline" : "mdi:circle"}
                    class="absolute -left-[11px] w-[10px] h-[10px] text-surface/70"
                />
                <button 
                    class="text-left w-full"
                    on:click={() => handleItemClick(item.id)}
                >
                    <span class="text-sm text-surface/90 block truncate pr-3">
                        {item.description}
                    </span>
                </button>
                <button
                    class="opacity-0 group-hover:opacity-100 transition-opacity text-surface/60 hover:text-surface/90 absolute right-1"
                    on:click={() => deleteItem(item.id)}
                    title="Delete entry"
                >
                    <Icon icon="mdi:close" class="w-4 h-4" />
                </button>
            </div>
        {/each}
    </div>
</div> 