import { RequestDocument, Variables, GraphQLClient } from 'graphql-request';
import { gql } from 'graphql-request';
import { getConfig } from './config';


export interface LPOEvent {
    id: string;
    userId: string;
    url: string;
    timestamp: number;
    action: string;
    path: string;
    elementLabel: string;
    outerHTML: string;
    x: number;
    y: number;
    value: string;
    weight: number;
  }
  
  export interface UserInteraction {
    action: string;
    url: string;
    path: string;
    elementLabel: string;
    probability: number;
  }
  
  export interface WorkflowPrediction {
    description: string;
    past: UserInteraction[];
    future: UserInteraction[];
  }
  
  export interface UIControl {
    url: string;
    path: string;
    elementLabel: string;
    description: string;
  }
  
  

let client: GraphQLClient | null = null;

export async function getGraphQLClient(): Promise<GraphQLClient> {
    if (!client) {
        const config = await getConfig();
        const serverUrl = await config.getServer() + "/graphql";
        const apiKey = await config.getApiKey();
        
        client = new GraphQLClient(serverUrl, {
            headers: {
                'Authorization': `bearer ${apiKey}`,
            },
        });
    }
    return client;
}

// Utility function for making requests
export async function makeGraphQLRequest<T = any>(
    document: RequestDocument,
    variables?: Variables
): Promise<T> {
    const client = await getGraphQLClient();
    return client.request<T>(document, variables);
} 


export async function getMonitoredUrls(): Promise<string[]> {
  const query = gql`
    query {
      organization {
        monitoredUrls
      }
    }
  `;
  const response = await makeGraphQLRequest(query);
  return response.organization.monitoredUrls;
}
