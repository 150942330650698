import { mount, unmount } from "svelte";
import AdminPanel from "../AdminPanel.svelte";
import Highlight from "../Highlight.svelte";
import Tooltip from "../Tooltip.svelte";
import styles from "../../app.css?inline";

function convertStyles(remSize: number) {
  // When injected into the shadow root, we have no control of the rem size as it is set on the html element.
  // So we need to convert all rem values in the tailwind css to px.
  return styles
    .split("}")
    .map((rule) => {
      if (rule.includes("rem")) {
        return (
          rule.replace(/(\d*\.?\d+)rem/g, (match, value) => {
            const pxValue = parseFloat(value) * remSize;
            return `${pxValue}px`;
          }) + "}"
        );
      }
      return rule + "}";
    })
    .join("")
    .slice(0, -1);
}

export function newShadowRoot(id: string) {
  // Convert all rem values to px
  const convertedStyles = convertStyles(16);

  const root = document.createElement("div");
  root.id = id;
  document.body.appendChild(root);
  const shadow = root.attachShadow({ mode: "open" });
  const styleNode = document.createElement("style");
  styleNode.textContent = convertedStyles;
  shadow.appendChild(styleNode);
  return shadow;
}

export class LightpostUI {
  private static appElement: HTMLElement | null = null;
  private static isVisible: boolean = false;
  private static adminPanel: any = null;

  private static loadVisibilityState(): boolean {
    const stored = sessionStorage.getItem("lightpost-ui-visible");
    return stored === "true";
  }

  private static saveVisibilityState(visible: boolean) {
    sessionStorage.setItem("lightpost-ui-visible", visible.toString());
  }

  private static async createSidePanel() {
    const shadow = newShadowRoot("lightpost-one-root");

    this.appElement = document.createElement("div");
    shadow.appendChild(this.appElement);

    console.log("Shadow root", shadow);

    this.adminPanel = mount(AdminPanel, {
      target: this.appElement,
      props: {},
    });
  }

  private static toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.saveVisibilityState(this.isVisible);
    if (this.appElement) {
      this.appElement.style.display = this.isVisible ? "block" : "none";
    }
  }

  public static async temporaryHighlight(
    ms: number,
    element: HTMLElement,
    color: string,
    label: string = "",
  ) {
    if (!this.appElement || !this.isVisible) {
      // UI is hidden, skip highlighting
      return;
    }

    const highlight = mount(Highlight, {
      target: this.appElement,
      props: {
        element: element,
        color: color,
        label: label,
      },
    });
    setTimeout(() => {
      unmount(highlight);
    }, ms);
  }

  public static async showTooltip(
    ms: number,
    element: HTMLElement,
    text: string,
  ) {
    if (!this.appElement || !this.isVisible) {
      console.log("No app element or UI is hidden, skipping tooltip");
      return;
    }

    const tooltip = mount(Tooltip, {
      target: this.appElement,
      props: {
        element: element,
        text: text,
      },
    });
    setTimeout(() => {
      unmount(tooltip);
    }, ms);
  }

  public static async init() {
    // Add keyboard shortcut listener
    document.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.altKey && event.key.toLowerCase() === "l") {
        this.toggleVisibility();
      }
    });

    setTimeout(async () => {
      await this.createSidePanel();
      // Set initial visibility from session storage
      this.isVisible = this.loadVisibilityState();
      if (this.appElement) {
        this.appElement.style.display = this.isVisible ? "block" : "none";
      }
    }, 1000);
  }
}
