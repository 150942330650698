export function resolveElement(path: string, text: string, doc: Document): HTMLElement[] {
  try {
    return Array.from(doc.querySelectorAll(path)).filter(el => 
      getElementText(el as HTMLElement) === text
    ) as HTMLElement[];
  } catch (error) {
    console.error('LPO: Error resolving element:', error);
    return [];
  }
}

export function getElementText(element: HTMLElement): string {
  let txt = element.innerText ?? '';
  if (txt == '') txt = element.title ?? '';
  if (txt == '') txt = element.getAttribute('aria-label') ?? '';
  if (txt == '') txt = element.getAttribute('alt') ?? '';
  if (txt == '') txt = element.getAttribute('placeholder') ?? '';
  if (txt == '') txt = element.getAttribute('data-testid') ?? '';
  if (txt == '') txt = "<unknown>";
  
  // Clean up the text
  txt = txt
    .replace(/\s+/g, ' ')    // Replace multiple spaces/tabs/newlines with single space
    .replace(/^\s+|\s+$/g, '') // Trim whitespace from start and end
    .replace(/[\u200B-\u200D\uFEFF]/g, ''); // Remove zero-width spaces and other invisible chars
  
  if (txt.length > 64) txt = txt.substring(0, 61) + "...";

  return txt;
}

export function getElementPath(element: HTMLElement): string {
  let path = "";
  while (element && element.tagName) {
    const tagName = element.tagName.toLowerCase();
    path = tagName + (path ? ">" + path : "");
    element = element.parentElement as HTMLElement;
  }
  return path;
}


export function whyInteractive(elem: HTMLElement, eventType: string): string[] {
  const reasons: string[] = [];

  if (elem.tagName === 'A' &&            ['click'].includes(eventType)) reasons.push('A');
  if (elem.tagName === 'BUTTON' &&       ['click'].includes(eventType)) reasons.push('Button');
  if (elem.tagName === 'SELECT' &&       ['change'].includes(eventType)) reasons.push('Select');
  if (elem.tagName === 'INPUT' &&        ['change'].includes(eventType)) reasons.push('Input');
  if (elem.tagName === 'TEXTAREA' &&     ['change'].includes(eventType)) reasons.push('Textarea');
  if (elem.hasAttribute('onkeydown') &&  ['keydown'].includes(eventType)) reasons.push('onkeydown');
  if (elem.hasAttribute('onkeyup') &&    ['keyup'].includes(eventType)) reasons.push('onkeyup');
  if (elem.hasAttribute('onclick') &&    ['click'].includes(eventType)) reasons.push('onclick');
  if (elem.hasAttribute('onmousedown') &&['mousedown'].includes(eventType)) reasons.push('onmousedown');
  if (elem.hasAttribute('onmouseup') &&  ['mouseup'].includes(eventType)) reasons.push('onmouseup');

  // Focusable elements
  if (elem.hasAttribute('tabindex') && parseInt(elem.getAttribute('tabindex') || '0', 10) >= 0) {
    if (['click'].includes(eventType)) {
      reasons.push('Tabindex');
    }
  }
  // ARIA roles
  const role = elem.getAttribute('role');
  if (role === 'button' && ['click', ].includes(eventType)) {
      reasons.push('ARIA button');
  }

  // TODO: Detect framework specific elements from MUI and similar.
  // TODO:dblclick and contextmenu currently doesnt match any reasons

  return reasons;
}
